<template>
  <v-container class="mt-9 pt-9">
    <SidebarBulk />
    <BackComponent
      :title_name="bulkItemState.menu_date"
      style="color: #EB5757;"
    />
    <div v-if="isLoading">
      <div v-for="i in 5" :key="i">
        <SkeletonProduct />
      </div>
    </div>
    <div v-else>
      <v-card class="mx-auto" max-width="344" flat>
        <v-card-text class="" style="padding: 3px 3px 3px 5px">
          <p
            class="text-h6 text--primary mb-0"
            style="text-transform: none;"
          >
            {{ $t("message.item") }}: {{ bulkItemState.item_name }}
          </p>
          <div class="mb-4">
            <v-icon size="20" class="pr-2">
              mdi-store
            </v-icon>
            <span style="color: black; font-size: 14px">{{ bulkStoreState.store_name }}</span>
          </div>
          <p class="d-flex justify-end text--primary">
            {{ $t("message.min-qty") }}: {{ bulkItemState.min_qty }}
          </p>
        </v-card-text>
      </v-card>

      <div v-if="addressList.length == 0">
        <v-container>
          <div>
            <h3 style="color: grey" class="text-center">
              <!-- {{ err_msg_listGroup }} -->
              List address not found
              <!-- {{ $t("message.err-list-bulk-address") }} -->
            </h3>
          </div>
        </v-container>
      </div>
      <div v-else>
        <div v-for="(listGroup, i) in addressList" :key="i">
          <div style="border: 1px solid black; border-radius: 5px;" class="mx-3 my-5">
            <v-card :disabled="listGroup.is_used == 0" class="mx-auto" flat max-width="344" @click.prevent="toCart(bulkItemState, listGroup)" >
              <v-card-text>
                <p class="text-h5 text--primary mb-0">
                  {{ listGroup.address_name }}
                </p>
                <p text--primary>
                  〒{{ formatPostalcode(listGroup.postal_code) }} {{ listGroup.prefecture }} {{ listGroup.city }} {{ listGroup.district }}
                </p>
                <div class="text--primary">
                  {{ listGroup.ward }} {{ listGroup.street_address }} {{ listGroup.building }}
                </div>
              </v-card-text>
            </v-card>
              <p class="text-center red--text mb-2" style="font-size: 12px; font-weight: 800" v-if="listGroup.is_used == 0">{{ $t("message.err-msg-address-cantUse") }}</p>
          </div>
        </div>
      </div>
      <div class="d-flex flex-row align-end justify-end mt-12 me-3 mb-2">
        <v-btn
          @click="addGroup('/bulk/' + setSlug_group + '/group-add')"
          rounded
          color="#ff0090"
          style="color: white"
          :disabled="bulkItemState.length == 0"
        >
          <v-icon left>
            mdi-plus
          </v-icon>
          {{ $t("message.btn-add") }}
        </v-btn>
      </div>
    </div>
  </v-container>
</template>

<script>
import SidebarBulk from "../../components/bulk/SidebarBulk.vue";
import Back from "@/components/Back.vue";
import SkeletonProduct from "../../components/skeleton/SkeletonProduct.vue";
import { mapState } from "vuex";
import postalcode from '../../data/postalcode.json'

export default {
  name: "GroupList",
  data() {
    return {
      setSlug_group: "",
      isLoading: true,
      postalData: postalcode
    };
  },
  components: {
    BackComponent: Back,
    SidebarBulk,
    SkeletonProduct,
  },
  computed: {
    addressList() {
      let addressList = this.$store.state.address_module.addressList
      let whiteListPostal = this.postalData
      for(let i = 0; i < addressList.length; i++) {
        for(let j = 0; j < whiteListPostal.length; j++) {
          if(addressList[i].postal_code !== whiteListPostal[j].value) {
            addressList[i].is_used = 0
          } else {
            addressList[i].is_used = 1
            break
          }
        }
      }
      // addressList.forEach(address => {
      //   whiteListPostal.forEach(listPostal => {
      //     if(address.postal_code !== listPostal.value) {
      //       address.is_used = 0
      //     } else {
      //       address.is_used = 1
      //       break
      //     }
      //   })
      // })
      return addressList
    },
    bulkItemState() {
      return this.$store.state.bulk_module.bulkItemInListGroup;
    },
    // bulkGroupState() {
    //   return this.$store.state.bulk_module.bulkGroupInListGroup;
    // },
    bulkStoreState() {
      return this.$store.state.bulk_module.bulkStoreInListGroup
    },
    err_msg_listGroup() {
      return this.$store.state.bulk_module.err_bulkListGroup;
    },
    ...mapState({
      bulkItemState: (state) => state.bulk_module.bulkItemInListGroup,
      // bulkGroupState: (state) => state.bulk_module.bulkGroupInListGroup,
      bulkStoreState: (state) => state.bulk_module.bulkStoreInListGroup
    }),
  },
  methods: {
    postal() {
      this.postalData = postalcode
    },
    async toCart(itemBulk, address) {
      let payload = {
        menu_date: itemBulk.menu_date,
        storeId: itemBulk.store_id,
        address: address.id,
        itemId: itemBulk.item_id
      }
      await this.$store.dispatch('bulk_module/fetchJoinCart', payload)
      this.$router.push("/carts")
    },
    formatPostalcode(postalcode) {
      var newPostcode = postalcode.slice(0, 3)+'-'+postalcode.slice(3)
      return newPostcode
    },
    async dispatchBulkItemState() {
      this.isLoading = true;
      let slugGroup = this.$router.history.current.params.slug_group;
      this.setSlug_group = slugGroup;
      await this.$store.dispatch("bulk_module/bulkListGroup", slugGroup);
      this.isLoading = false;
    },
    async dispatchAddress() {
      this.isLoading = true;
      let data = await this.$store.dispatch("address_module/fetchAddress");
      this.isLoading = false;
      return data;
    },
    addGroup(link) {
      this.$router.push(link);
    },
  },
  created() {
    this.dispatchBulkItemState();
    this.dispatchAddress()
    this.postal()
  },
};
</script>

<style>
.card-class {
  /* border: 1px solid black !important; */
}
</style>
